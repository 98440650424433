import PageLayout from "./page-layout"
import React, { useEffect } from 'react'
import { Form12J } from "../components/12j-form"
import ReactDOM from 'react-dom';
import { FormPrivateEquity } from "../components/private-equity-form";

const Page = ({
    data
}) => {
    const post = data.markdownRemark

    useEffect(() => {
      if (document.querySelector('#form-12j')) {
        ReactDOM.render(<Form12J />, document.querySelector('#form-12j'));
      }

      if (document.querySelector('#form-equity-partner')) {
        ReactDOM.render(<FormPrivateEquity />, document.querySelector('#form-equity-partner'));
      }
      
    }, [])

    return (
        <PageLayout>
            <div style={{padding: 10}}>
            <h1>{post.frontmatter.title}</h1>
            {post.frontmatter.investment && <h3>{post.frontmatter.investment}</h3>}
            {post.frontmatter.image && <img alt={post.frontmatter.title} src={post.frontmatter.image} style={{width: '100%'}} />}
            <div style={{
              marginTop: 40, 
              marginBottom: 40, 
              fontFamily: 'Work Sans', 
              fontSize: '1.2em'
            }} 
            dangerouslySetInnerHTML={{ __html: post.html }} />

            {post.fields.type === 'project' && post.frontmatter.status === 'open' && (
              <div>
                <FormPrivateEquity projectName={post.frontmatter.title} />
              </div>
            )}
            </div>
        </PageLayout>
    )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        type
      }
      frontmatter {
        title
        image
        status
      }
    }
  }
`