import React, { useState } from 'react'
import { Row, Col, Form, Input, Button, Card, Alert } from 'antd'
import { Theme } from '../styles/theme'
import { FormService } from '../services/form-service'

export const Form12J = () => {
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)

    const submit = ({
        name, phone, email, message
    }) => {
        setLoading(true)
        setError(null)
        setSent(null)

        FormService.addContect(
            name,
            phone,
            email,
            `12J\n\n${message}`
        ).then(() => {
            setLoading(false)
            setSent(true)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setError('We could not send your enquery. Please try again')
        })
    }

    return (
        <div style={{marginTop: 20}}>
            
            <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Col xs={24} sm={24} md={12}>
                    <Card>
                    <h2>Send us an investment query</h2>
                    {sent && <Alert type='success' message='Query sent successfully' style={{marginBottom: 20}} />}
                    {error && <Alert type='error' message={error} style={{marginBottom: 20}} />}
                    <Form wrapperCol={{span: 24}} layout='vertical' onFinish={submit}>
                        <Form.Item name='name' rules={[
                            {required: true, message: 'Your name is required'}
                        ]}>
                            <Input placeholder='Name *' style={{width: '100%'}} />
                        </Form.Item>
                        <Form.Item name='phone' rules={[
                            {required: true, message: 'Your phone number is required'}
                        ]}>
                            <Input placeholder='Phone Number *' type='tel' />
                        </Form.Item>
                        <Form.Item name='email' rules={[
                            {required: true, message: 'Your email address is required'},
                            {type: 'email', message: 'Please input a valid email address'}
                        ]}>
                            <Input placeholder='Email Address *' type='email' />
                        </Form.Item>
                        <Form.Item name='message' rules={[{
                            required: false
                        }]}>
                            <Input.TextArea style={{height: 80}} placeholder='Message' />
                        </Form.Item>
                        {/* <Form.Item name='investment' label='How much are you willing to invest?'>
                            <Slider marks={marks} defaultValue={30} min={500000} max={20000000} step={100000} />
                        </Form.Item> */}

                        <Button block htmlType='submit' type='primary' loading={loading} style={{background: Theme.secondaryColor, height: 50, border: 'none'}}>Invest</Button>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}