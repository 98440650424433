import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { Theme } from '../styles/theme'

const PageLayout = ({
    children
}) => {
	const menuOpenStyle = {
		visibility: 'visible',
		display: 'block',
		opacity: 1,
		transform: 'scale(1)',
		top: 135,
		zIndex: 10000,
		pointerEvents: 'all'
	}

	const [menuOpen, setMenuOpen] = useState(false)

	const toggleMenu = () => {
		setMenuOpen(!menuOpen)
	}

    return (
        <div id="page" className="site">
	<a className="skip-link screen-reader-text" href="#content">Skip to content</a>

		
<header id="masthead" className="site-header">
	
	<div className="container">
		<div className="top-section d-none d-md-block">
			<div className="row">
				<div className="left-content col-md-6 col-12 text-md-left text-center">
				<span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i>Plot 293, Protea Road Cnr Methley Road, Pomona AH, 1619</span>				</div>
				<div className="botton-section col-md-6 col-12 text-md-right text-center">
										<ul>
						<li><i className="fa fa-phone" aria-hidden="true"></i>(+27) 012 546 0966</li>
						<li><i className="fa fa-clock-o" aria-hidden="true"></i>08AM - 5PM</li>
					</ul>				</div>
			</div>
		</div>
		<div className="row bottom-section align-items-center">
			<div className="site-branding col-md-4 col-sm-6 col-lg-3 col-9">
				<Link to='/' className="custom-logo-link" rel="home"><img width='280px' src="/imgs/logo.png" className="custom-logo" alt="Airi" /></Link>			</div>

			<div className="header-mobile-menu col-lg-9 col-md-8 col-sm-6 col-3">
			<button className="mobile-menu-toggle" aria-controls="primary-menu" onClick={toggleMenu}>
					<MenuOutlined style={{color: Theme.secondaryColor}} />
					{/* <span className="mobile-menu-toggle_lines"></span>
					<span className="sr-only">Toggle mobile menu</span> */}
				</button>
			</div>			
			<div className="right-content col-xl-9 col-md-12">
				<div className="d-flex align-items-center justify-content-end">
					<nav id="site-navigation" className="main-navigation" style={menuOpen ? menuOpenStyle : {}}  onClick={toggleMenu}>
						<div className="menu-menu-container"><ul id="primary-menu" className="menu"><li id="menu-item-141" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-4 current_page_item menu-item-141"><Link to='/' aria-current="page">Home</Link></li>
						<li id="menu-item-143" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-143"><a href='/#about'>About</a></li>
<li id="menu-item-143" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-143"><a href='/#investment'>Investment</a></li>
<li id="menu-item-143" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-143"><Link to="/projects">Projects</Link></li>
<li id="menu-item-144" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-144"><Link to='/contact'>Contact</Link></li>
</ul></div>					</nav>
					<div className="group-actions d-none d-xl-flex align-items-center">

						<div className="search-form">
									
									{/* <div className="header-search-form">					</div> */}
						</div>
													<Link to='/contact' className="btn">
								Invest							</Link>
											</div>
				</div>
			</div>
		</div>
	</div>


</header>
	<div id="content" className="site-content">

	
    <div id='primary' className='content-area' style={{minHeight: 400}}>
        <main id="main" class="site-main" role="main" style={{paddingTop: 40}}>
        <div data-elementor-type="wp-post" data-elementor-id="134" class="elementor elementor-134" data-elementor-settings="[]">
			<div class="elementor-inner">
				<div class="elementor-section-wrap">
                <section class="elementor-element elementor-element-4a58e20a elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="4a58e20a" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
				{children}
			</div>
		</section>
                </div>
            </div>
        </div>
        </main>
    </div>


	</div>

	
	
	
	
	<div id="sidebar-footer" className="footer-widgets" role="complementary">
		<div className="container">
			<Row style={{fontSize: '1.2em'}} gutter={[32, 32]}>
							<Col md={8}>
					<section id="media_image-1" className="widget widget_media_image">
						{/* <a href="#"><img width="180px" src="imgs/logo.png" className="image wp-image-148  attachment-full size-full" alt="" style={{maxWidth: '100%', height: 'auto'}} /></a> */}
						</section><section id="custom_html-1" className="widget_text widget widget_custom_html"><div className="textwidget custom-html-widget"><p className="about-us-footer">
					TJC has a broad skill set in property development to select great developments, it has the network to connect these projects to investors and it has the experience to help ensure the property is governed well.  The company
</p></div></section><section id="athemes_social_widget-1" className="widget widget_athemes_social_widget">                <div className="menu-social-container"><ul id="menu-social" className="menu social-media-list clearfix"><li id="menu-item-150" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-150"><a target='_blank' rel="noreferrer" href="https://www.facebook.com/theJosephCompanyEquity"><span className="screen-reader-text">Facebook</span></a></li>
{/* <li id="menu-item-151" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-151"><a href="http://instagram.com/"><span className="screen-reader-text">Instagram</span></a></li> */}
<li id="menu-item-152" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-152"><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/posts/the-joseph-company-equity-investments_investsa-venturecapitalist-gauteng-activity-6690893921301626880-zB6F"><span className="screen-reader-text">Linkedin</span></a></li>
</ul></div>        </section>				</Col>
			
										<Col md={8}>
					<section id="custom_html-2" className="widget_text widget widget_custom_html"><h3 className="widget-title" style={{color: 'white'}}>Contact Us</h3><div className="textwidget custom-html-widget"><p style={{marginBottom: 12}}>
					Plot 293, Protea Road Cnr Methley Road, Pomona AH, 1619
</p>
<p style={{marginBottom: 6}} >
	<span style={{color: '#F1A52E', marginRight: 5}}>Phone:</span>(+27) 012 546 0966
</p>
<p style={{marginBottom: 6}}>
	<span style={{color: '#F1A52E', marginRight: 5}}>Email:</span><a href="mailto:info@thejosephcompany.co.za" className="__cf_email__" >info@thejosephcompany.co.za</a>

</p>
<p>
	<span style={{color: '#F1A52E', marginRight: 5}}>Website:</span>www.thejosephcompany.co.za
</p></div></section>				</Col>

<Col md={8}>
					<section id="custom_html-2" className="widget_text widget widget_custom_html"><h3 className="widget-title" style={{color: 'white'}}>Partners</h3><div className="textwidget custom-html-widget">
					<img src='/imgs/partner-4.png' style={{height: 60, marginBottom: 20}} />
					<img src='/imgs/partner-5.png' style={{height: 60, marginBottom: 20}} />
					{/* <img src='/imgs/partner-2.png' style={{height: 60, marginBottom: 20}} />
					<img src='/imgs/partner-3.jpg' style={{height: 60, marginBottom: 20}} /> */}
					</div></section>				</Col>
					
			</Row>
		</div>	
	</div>
	<footer id="colophon" className="site-footer">
		<div className="container">
			<div className="row">
					
	<div className="site-info col-md-12">
		
					{/* <a href="https://wordpress.org/">Proudly powered by WordPress</a> */}
			{/* <span className="sep"> | </span> */}
			&copy; The Joseph Company 2020		</div>
	
				</div>
		</div>
	</footer>
</div>
    )
}

export default PageLayout